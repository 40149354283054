<template>
  <b-card-text>
    <div class="card w-100 text-center" v-if="loading">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="error">
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <span class="mb-2 d-block">Erro ao carregar dados!</span>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="frete == null">
      <div class="card-body py-5 text-center">
        <h5 class="mb-3">Nenhum item encontrado!</h5>
        <img width="250" src="@/assets/images/not-data.svg" />
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-body linkmenus">
        <h4 class="card-title">Frete</h4>
        <hr />
        <div class="row">
          <div class="col-sm-12 col-md-6 mb-3">
            <label>CEP de Origem</label>
            <b-form-input
              v-model="frete.cep"
              placeholder="CEP"
              for="text"
            ></b-form-input>
            <small>Este CEP será utilizado para o cálculo do frete.</small>
          </div>
          <div class="col-sm-12 col-md-6 mb-3">
            <label>
              Serviços
              <i
                class="fas fa-exclamation-circle mr-3"
                v-b-tooltip.hover.top
                title="SEDEX à vista: 04014 | PAC à vista: 04510 | SEDEX 12 (à vista): 04782 | SEDEX 10 (à vista): 04790 | SEDEX Hoje à vista: 04804"
              ></i>
            </label>
            <b-form-input
              v-model="frete.service"
              placeholder="Serviços"
              for="text"
            ></b-form-input>
            <small>
              Utilize a vírgula para separar os serviços (00000, 00000...).
            </small>
          </div>
          <div class="col-sm-12 col-md-6 mb-3">
            <label>Usuário</label>
            <b-form-input
              v-model="frete.empresa"
              placeholder="Usuário"
              for="text"
            ></b-form-input>
            <small>*apenas se tiver contrato com o correios.</small>
          </div>
          <div class="col-sm-12 col-md-6 mb-3">
            <label>Senha</label>
            <b-form-input
              v-model="frete.senha"
              placeholder="Password"
              for="text"
              type="password"
            ></b-form-input>
            <small>*apenas se tiver contrato com o correios.</small>
          </div>
          <div class="col-sm-12 col-md-6 mb-3">
            <label>Valor Adicional</label>
            <b-form-input
              v-model="frete.valor_adicional"
              for="text"
              placeholder="0,00"
              v-money="money"
            ></b-form-input>
            <small>Valor de preparo para envio.</small>
          </div>
          <div class="col-sm-12 col-md-6 mb-3">
            <label>Entrega em mãos</label>
            <b-form-select
              v-model="frete.entrega_mao"
              label="text"
              placeholder="Entrega em mãos"
              :options="options"
            ></b-form-select>
          </div>
          <div class="col-12">
            <b-button variant="success" @click.prevent="salvarDados()">
              <i class="bx bx-save font-size-16 align-middle mr-2"></i>
              Salvar
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-card-text>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      options: [
        {value: "N", text:"Não"},
        {value: "S", text:"Sim"},
      ],
      frete: null,
      loading: true,
      error: false,
    }
  },
  created() {
    this.listFrete()
  },
  methods: {
    ...mapActions('setting', ['ActionFreteFind', 'ActionFreteUpdate']),
    async salvarDados() {
      try {
        this.frete.valor_adicional = parseFloat(this.frete.valor_adicional)
        await this.ActionFreteUpdate(this.frete)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (e) {
        console.log(e)
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async listFrete() {
      try {
        this.loading = true
        await this.ActionFreteFind().then((res) => {
          this.frete = res.data.data
          this.frete.valor_adicional = this.frete.valor_adicional.toFixed(2)
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.frete = null
        this.loading = false
        this.error = true
      }
    },
  },
}
</script>
