<template>
  <b-card-text>
    <div class="card w-100 text-center" v-if="loading">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="error">
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <span class="mb-2 d-block">Erro ao carregar dados!</span>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="store == null">
      <div class="card-body py-5 text-center">
        <h5 class="mb-3">Nenhum item encontrado!</h5>
        <img width="250" src="@/assets/images/not-data.svg" />
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-body linkmenus">
        <h4 class="card-title">Opções de Parcelamento</h4>
        <hr />
      <b-tabs pills nav-class="p-0" nav-wrapper-class="w-100">
        <b-tab active>
          <template v-slot:title>
            <p class="font-weight-bold p-0 m-0 mx-3 my-2">Parcelamento</p>
          </template>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 mb-4">
                    <label>Quantidade máxima de parcelas</label>
                    <b-form-select
                      v-model="store.installment"
                      label="text"
                      placeholder="Parcelamento"
                      :options="options"
                    ></b-form-select>
                  </div>
                  <div class="col-12 mb-4">
                    <label>Quantidade de parcelas sem juros</label>
                    <b-form-select
                      v-model="store.installment_juros"
                      label="text"
                      placeholder="Parcelamento sem juros"
                      :options="options"
                    ></b-form-select>
                  </div>
                  <div class="col-12 mb-4">
                    <label>Juros (%)</label>
                    <b-form-input
                      v-model="store.juros"
                      placeholder="juros (%)"
                      for="text"
                      v-money="money"
                    ></b-form-input>
                    <small>% de juros</small>
                  </div>
                  <div class="col-12 mt-3">
                    <b-button variant="success" @click.prevent="salvarDados()">
                      <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                      Salvar
                    </b-button>
                  </div>
                </div>
              </div>
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <p class="font-weight-bold p-0 m-0 mx-3 my-2">Estabelecimento</p>
          </template>
          <div class="row">
            <div class="col-12 text-center d-none">
              <hr/>
                <p>Adicione seu estabelecimento</p>
                <b-button variant="success" class="py-3 px-5">
                  Adicionar
                </b-button>
            </div>
            <div class="col-12 text-center">
              <hr/>
              <h4>Adicione seu estabelecimento</h4>
              <p>Documentos necessários:</p>
              <strong>Documento de Identificação</strong><p>Documento com foto (RG, CPF ou CNH do sócio) Cartão CNPJ (apenas em caso de Pessoa Jurídica)</p>
              <strong>Comprovante de Atividade</strong><p>Alvará, nota fiscal de compras de produtos ou cartão de visitas</p><br/>
              <strong>Comprovante de Residência</strong><p>Conta de luz, telefone ou gás no nome do sócio ou de familiares</p><br/>
              <strong>Identificação CNPJ</strong><p>CNPJ</p>
              <strong>Encaminhar os documentos para: <i class="text-danger">thiagom896@gmail.com</i></strong>

            </div>
            <div class="col-12 my-3 text-right d-none">
              <b-button variant="success">
                <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                Salvar
              </b-button>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <p class="font-weight-bold p-0 m-0 mx-3 my-2">Taxas</p>
          </template>
          <div class="row">
            <div class="col-12 text-left">
              <hr/>
              <h6 class="text-center w-100">As taxas abaixo são em relação aos pagamentos online realizados na loja virtual.</h6>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">Meio de Pagamento</th>
                      <th scope="col">Taxa Fixa</th>
                      <th scope="col">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Débito</th>
                      <td>R$ 0,00</td>
                      <td>2.84% à 3.73%</td>
                    </tr>
                    <tr>
                      <th>Débito</th>
                      <td>R$ 0,00</td>
                      <td>2.84% à 3.73%</td>
                    </tr>
                    <tr>
                      <th>Crédito à vista</th>
                      <td>R$ 0,00</td>
                      <td>4.08% à 4.77%</td>
                    </tr>
                    <tr>
                      <th>Boleto</th>
                      <td>R$ 2.90</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 my-3 text-right d-none">
              <b-button variant="success">
                <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                Salvar
              </b-button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
       </div>
    </div>
  </b-card-text>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      store: null,
      loading: true,
      error: false,
      options: [
        { value: 1, text: '1X' },
        { value: 2, text: '2X' },
        { value: 3, text: '3X' },
        { value: 4, text: '4X' },
        { value: 5, text: '5X' },
        { value: 6, text: '6X' },
        { value: 7, text: '7X' },
        { value: 8, text: '8X' },
        { value: 9, text: '9X' },
        { value: 10, text: '10X' },
        { value: 11, text: '11X' },
        { value: 12, text: '12X' },
      ],
    }
  },
  created() {
    this.findStore()
  },
  methods: {
    ...mapActions('appearance', [
      'ActionAppearanceFind',
      'ActionAppearanceUpdate',
    ]),
    async salvarDados() {
      try {
        await this.ActionAppearanceUpdate(this.store)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async findStore() {
      try {
        await this.ActionAppearanceFind().then((res) => {
          this.store = res.data.data
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.store = null
      }
    },
  },
}
</script>
