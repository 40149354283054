<template>
  <b-card-text>
    <div class="card w-100 text-center" v-if="loading">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="error">
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <span class="mb-2 d-block">Erro ao carregar dados!</span>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="notificacao == null">
      <div class="card-body py-5 text-center">
        <h5 class="mb-3">Nenhum item encontrado!</h5>
        <img width="250" src="@/assets/images/not-data.svg" />
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <div>
          <h4 class="card-title">Notificações</h4>
          <hr />
          <div class="row">
            <div class="col-12 mb-4">
              <label>E-mail para receber notificações</label>
              <b-form-input
                v-model="notificacao.email"
                placeholder="e-mail"
                for="text"
              ></b-form-input>
            </div>
            <div class="col-12 mb-3">
              <b-form-checkbox
                switch
                size="md"
                class="mb-2"
                v-model="notificacao.customer"
              >
                Notificações de novos clientes
              </b-form-checkbox>
              <b-form-checkbox
                switch
                size="md"
                class="mb-2"
                v-model="notificacao.sales"
              >
                Notificações de novos pedidos
              </b-form-checkbox>
              <b-form-checkbox
                switch
                size="md"
                class="mb-2"
                v-model="notificacao.payment_status"
              >
                Notificações de alterações no status de pagamento do pedido
              </b-form-checkbox>
              <b-form-checkbox
                switch
                size="md"
                class="mb-2"
                v-model="notificacao.shipping_status"
              >
                Notificações de alterações no status de frete do pedido
              </b-form-checkbox>
              <b-form-checkbox
                switch
                size="md"
                class="mb-2"
                v-model="notificacao.subscription_newslatter"
              >
                Notificações de novas assinaturas da newsletter
              </b-form-checkbox>
              <b-form-checkbox
                switch
                size="md"
                class="mb-2"
                v-model="notificacao.contact"
              >
                Notificações de novos contatos
              </b-form-checkbox>
            </div>
            <div class="col-12 mt-3">
              <b-button variant="success" @click.prevent="salvarDados()">
                <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                Salvar
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card-text>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      notificacao: null,
      loading: true,
      error: false,
    }
  },
  created() {
    this.listNotificacao()
  },
  methods: {
    ...mapActions('setting', [
      'ActionNotificacaoFind',
      'ActionNotificacaoUpdate',
    ]),
    async salvarDados() {
      try {
        await this.ActionNotificacaoUpdate(this.notificacao)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async listNotificacao() {
      try {
        this.loading = true
        await this.ActionNotificacaoFind().then((res) => {
          this.notificacao = res.data.data
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.notificacao = null
        this.loading = false
        this.error = true
      }
    },
  },
}
</script>
