<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Configurações</h4>
        </div>
      </div>
    </div>
    <div class="row text-left checkout-tabs">
      <div class="col-12">
        <b-tabs
          pills
          vertical
          nav-class="p-0"
          nav-wrapper-class="col-lg-3 w-100"
        >
          <b-tab active>
            <template v-slot:title>
              <p class="font-weight-bold m-0 p-0 text-left my-3">
                <i class="fas fa-shipping-fast check-nav-icon mr-3"></i>
                Frete
              </p>
            </template>
            <Frete />
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <p class="font-weight-bold m-0 p-0 text-left my-3"><i class="fas fa-envelope-open-text check-nav-icon mr-3"></i>Modelos de E-mails</p>
            </template>
            <Template/>
          </b-tab>
          <!--<b-tab>
            <template v-slot:title>
              <p class="font-weight-bold m-0 p-0 text-left my-3"><i class="fas fa-envelope check-nav-icon mr-3"></i>E-mail</p>
            </template>
            <b-card-text>
              <div class="card">
                <div class="card-body">
                  <div>
                    <h4 class="card-title">E-mail</h4>
                    <hr/>
                    <div class="row">
                      <div class="col-md-12 col-lg-6 mb-3">
                        <label>Usuário</label>
                        <b-form-input placeholder="Usuário" for="text"></b-form-input>
                      </div>
                      <div class="col-md-12 col-lg-6 mb-3">
                        <label>Senha</label>
                        <b-form-input placeholder="Senha" for="password"></b-form-input>
                      </div>
                      <div class="col-md-12 col-lg-6 mb-3">
                        <label>SMTP</label>
                        <b-form-input placeholder="SMTP" for="text"></b-form-input>
                      </div>
                      <div class="col-md-12 col-lg-6 mb-3">
                        <label>Porta</label>
                        <b-form-input placeholder="Porta" for="text"></b-form-input>
                      </div>
                      <div class="col-12 mt-3">
                        <b-button variant="success">
                            <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                            Salvar
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-tab>-->
          <b-tab>
            <template v-slot:title>
              <p class="font-weight-bold m-0 p-0 text-left my-3">
                <i class="fas fa-bell check-nav-icon mr-3"></i>
                Notificações
              </p>
            </template>
            <Notificacoes />
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <p class="font-weight-bold m-0 p-0 text-left my-3">
                <i class="fas fa-money-bill-wave check-nav-icon mr-3"></i>
                Pagamento
              </p>
            </template>
            <Payment />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<style>
.check-nav-icon {
  font-size: 20px !important;
}

.checkout-tabs .linkmenus .nav-pills .nav-link {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.checkout-tabs .linkmenus .nav-pills .nav-link.active {
  background-color: #556ee6;
}
.curpoint:hover {
  cursor: pointer !important;
}
</style>
<script>
import Multiselect from 'vue-multiselect'
import Frete from './components/Frete'
import Notificacoes from './components/Notificacoes'
import Payment from './components/Payment'
import Template from './components/Template'
export default {
  components: {
    Multiselect,
    Frete,
    Notificacoes,
    Payment,
    Template
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>
