<template>
  <b-card-text>
    <div class="card w-100 text-center" v-if="loading">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="error">
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <span class="mb-2 d-block">Erro ao carregar dados!</span>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="emails.length <= 0">
      <div class="card-body py-5 text-center">
        <h5 class="mb-3">Nenhum item encontrado!</h5>
        <img width="250" src="@/assets/images/not-data.svg" />
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <div>
          <h4 class="card-title">Modelos de E-mails</h4>
          <hr />

          <div role="tablist" v-for="(email, index) in emails" :key="index">
            <b-card no-body class="mb-2 shadow-none">
              <b-card-header
                class="curpoint"
                header-tag="header"
                role="tab"
                v-b-toggle="'collapse-'+index"
              >
                <h6 class="m-0 py-2">
                  {{ email.subject }}
                </h6>
              </b-card-header>
              <b-collapse
                :id="'collapse-'+index"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label>Assunto</label>
                        <b-form-input
                          placeholder="Assunto"
                          for="text"
                          v-model="email.subject"
                        ></b-form-input>
                      </div>
                       <div class="col-12 mb-3 py-3 text-dark">{{email.variaveis}}</div>
                      <div class="col-12 mb-3">
                        <b-form-group>
                          <label>Conteúdo</label>
                          <quill-editor
                            v-model="email.body"
                            ref="quillEditorA"
                          ></quill-editor>
                        </b-form-group>
                      </div>
                      <div class="col-12">
                        <b-button variant="success" @click.prevent="salvarDados(email)">
                          <i
                            class="bx bx-save font-size-16 align-middle mr-2"
                          ></i>
                          Salvar
                        </b-button>
                      </div>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </b-card-text>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      emails: [],
      loading: true,
      error: false,
    }
  },
  created() {
    this.listEmail()
  },
  methods: {
    ...mapActions('setting', [
      'ActionTempalteEmailAll',
      'ActionTemplateEmailUpdate',
    ]),
    async salvarDados(data) {
      try {
        await this.ActionTemplateEmailUpdate(data)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async listEmail() {
      try {
        this.loading = true
        await this.ActionTempalteEmailAll().then((res) => {
          this.emails = res.data.data
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.emails = []
        this.loading = false
        this.error = true
      }
    },
  },
}
</script>
